import axios from '@axios'

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {
 
    },
    actions: {
        fetchCatalogues(ctx, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/api/vehicules/catalogue', { params: payload })
                    .then(response => resolve(response.data))
                    .catch(error => reject(error))
            })
        },
        fetch(ctx, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/api/vehicules/catalogue/' + payload.id, { params: payload })
                    .then(response => resolve(response.data))
                    .catch(error => reject(error))
            })
        },
        // creerOffre(ctx, payload) {
        //     return new Promise((resolve, reject) => {
        //         axios
        //             .post('/api/vehicules/catalogue/creer-offre' , payload)
        //             .then(response => resolve(response.data))
        //             .catch(error => reject(error))
        //     })
        // },


    },
}
