require('dotenv').config()
import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import router from './router'
import store from './store'
import App from './App.vue'


// Global Components
import './global-components'
import '@/libs/vue-select'

// 3rd party plugins
import '@/libs/acl/index.js'
import '@/libs/portal-vue'
import '@/libs/toastification'
 
// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')
require('@core/assets/fonts/feather/iconfont.css')

// import assets styles
require('@core/scss/vue/libs/vue-select.scss') ;
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
