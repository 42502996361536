import axios from '@axios'

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {
 
    },
    actions: {
        fetchMarchans(ctx, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/api/marchand', { params: payload })
            })
        },
        
    },
}
