import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import dashboardModule from './dashboardModule'
import catalogueModule from './catalogueModule'
import clientsModule from './clientsModule'
import gestionStockModule from './gestionStockModule'
import listBcModule from './listBcModule'
import livraisonModule from './livraisonModule'
import marchandModule from './marchandModule'
import repriseModule from './repriseModule'
import venduModule from './venduModule'
import initialConfigModule from './initialConfigModule'
import refModule from './refModule'
import userModule from './userModule'
import siteModule from './siteModule'
import concessionModule from './concessionModule'
import eCommerceStoreModule from './eCommerceStoreModule'
import laCoteModule from './laCoteModule'
import offreModule from './offreModule'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        app,
        appConfig,
        verticalMenu,
        dashboardModule,
        catalogueModule,
        clientsModule,
        gestionStockModule,
        listBcModule,
        livraisonModule,
        marchandModule,
        repriseModule,
        venduModule,
        initialConfigModule,
        refModule,
        userModule,
        siteModule,
        concessionModule,
        eCommerceStoreModule,
        laCoteModule,
        offreModule,
    },
    strict: process.env.DEV,
})
