import axios from '@axios'

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {
 
    },
    actions: {
        fetchAll(ctx, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/api/sites', { params: payload })
                    .then(response => resolve(response.data))
                    .catch(error => reject(error))
            })
        },
        fetch(ctx, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/api/sites/' + payload.site_id)
                    .then(response => resolve(response.data))
                    .catch(error => reject(error))
            })
        },
        siteStore(ctx, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/api/sites/store',  payload)
                    .then(response => resolve(response.data))
                    .catch(error => reject(error))
            })
        },
        
    },
}
