import axios from '@axios'

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {
 
    },
    actions: {
        fetchAll(ctx, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/api/concessions', { params: payload })
                    .then(response => resolve(response.data))
                    .catch(error => reject(error))
            })
        },
        fetch(ctx, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/api/concessions/' + payload.concession_id)
                    .then(response => resolve(response.data))
                    .catch(error => reject(error))
            })
        },
        store(ctx, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/api/concessions/store',  payload)
                    .then(response => resolve(response.data))
                    .catch(error => reject(error))
            })
        },
        
    },
}
