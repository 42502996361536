import axios from '@axios'

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {

    },
    actions: {
        fetchAll(ctx, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/api/offres', { params: payload })
                    .then(response => resolve(response.data))
                    .catch(error => reject(error))
            })
        },
        fetch(ctx, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/api/offres/' + payload.offre_id, { params: payload })
                    .then(response => resolve(response.data))
                    .catch(error => reject(error))
            })
        },
        store(ctx, payload){
            return new Promise((resolve, reject) => {
                axios
                    .post('/api/offres/store',payload)
                    .then(response => resolve(response.data))
                    .catch(error => reject(error));
            })
        },
        addVehicule(ctx, payload){
            return new Promise((resolve, reject) => {
                axios
                    .post('/api/offres/' + payload.offre_id + '/add-vehicule-vente',{
                        vehicule : payload.vehicule
                    })
                    .then(response => resolve(response.data))
                    .catch(error => reject(error))
            })
        },
        deleteVehiculeVente(ctx, payload){
            return new Promise((resolve, reject) => {
                axios
                    .post('/api/offres/' + payload.vehicule_vente_id + '/delete-vehicule-vente')
                    .then(response => resolve(response.data))
                    .catch(error => reject(error))
            })
        },
        deleteVehiculeReprise(ctx, payload){
            return new Promise((resolve, reject) => {
                axios
                    .post('/api/offres/' + payload.vehicule_reprise_id + '/delete-vehicule-reprise')
                    .then(response => resolve(response.data))
                    .catch(error => reject(error))
            })
        }
        

    },
}
