import axios from '@axios'
import useJwt from "@/auth/jwt/useJwt";
import {initialAbility} from "@/libs/acl/config";
import router from "@/router";

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {
 
    },
    actions: {
        fetchUtilisateurs(ctx, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/api/utilisateurs', { params: payload })
                    .then(response => resolve(response.data))
                    .catch(error => reject(error))
            })
        },
        userStore(ctx, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/api/utilisateurs/store',  payload)
                    .then(response => resolve(response.data))
                    .catch(error => reject(error))
            })
        },
        
        fetch(ctx, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/api/utilisateurs/' + payload.utilisateur_id)
                    .then(response => resolve(response.data))
                    .catch(error => reject(error))
            })
        },
        logout(ctx, payload) {
      
            // Remove userData from localStorage
            // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
            localStorage.clear()

            // Remove userData from localStorage
            localStorage.removeItem('userData')

 
            // Redirect to login page
            router.push({ name: 'auth-login' })
        }
    },
}
