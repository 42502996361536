import axios from '@axios'
export default {
    namespaced: true,
    state: {
    },
    getters: {},
    mutations: {

    },
    actions: {
        fetchMarques(ctx, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .get('https://ref.africavo.ma/api/marques', { params: payload })
                    .then(response => resolve(response.data))
                    .catch(error => reject(error))
            })
        },
        fetchModeles(ctx, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .get('https://ref.africavo.ma/api/modeles', { params: payload })
                    .then(response => resolve(response.data))
                    .catch(error => reject(error))
            })
        },
        fetchEnergie(ctx, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .get('https://ref.africavo.ma/api/energies', { params: payload })
                    .then(response => resolve(response.data))
                    .catch(error => reject(error))
            })
        },
        fetchTypeBoite(ctx, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .get('https://ref.africavo.ma/api/type-boites', { params: payload })
                    .then(response => resolve(response.data))
                    .catch(error => reject(error))
            })
        },
        fetchVersions(ctx, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .get('https://ref.africavo.ma/api/motorisations', { params: payload })
                    .then( function (response){
                        resolve (response.data.versions.map(function (item){
                        
                            return {
                                libelle : item.libelle_version_long,
                                puissance_din : item.puissance_din,
                                cv_fiscaux : item.cv_fiscaux,
                                emission_co2 : item.techniques ? item.techniques.co2 : '',
                                nbr_portes : item.techniques ? item.techniques.nombre_de_portes : '',
                                nbr_places : item.techniques ? item.techniques.nb_places : '',
                                volume_coffre : item.techniques ? item.techniques.volume_coffre : '',
                                poids_a_vide : item.techniques ? item.techniques.poids_a_vide : '',
                                ptac : item.techniques ? item.techniques.ptac : '',
                                reservoir : item.techniques ? item.techniques.reservoir : '',
                                carrosserie : item.techniques ? (item.techniques.carrosserie_referentiel ? item.techniques.carrosserie_referentiel.libelle : '') : '',
                                identifiant : item.identifiant,
                            }
                        }));
                    })
                    .catch(error => reject(error))
            })
        },

    },
}
