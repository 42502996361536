import Vue from 'vue'
import router from '../router/index'

// axios
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useJwt from "@/auth/jwt/useJwt";
import store from '@/store/index'
import {initialAbility} from "@/libs/acl/config";

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: process.env.VUE_APP_API_URL,
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
})
// axiosIns.interceptors.request.use(function(res){
//     console.log(res);
//     res.params['XDEBUG_SESSION_START']=1;
//     return res;
// }, function (error) {
//     // Do something with request error
//     return Promise.reject(error);
// });

//add params to all request
axiosIns.interceptors.request.use(function (config) {
    // Do something before request is sent
    config.params = config.params || {};
    if(store.state.initialConfigModule.concession){
        config.params['concession_id']=store.state.initialConfigModule.concession.id;
    }
    if(store.state.initialConfigModule.site){
        config.params['site_id']=store.state.initialConfigModule.site.id;
        
    }
    return config;
  } , function (error) {
    // Do something with request error
    return Promise.reject(error);
  } );




axiosIns.interceptors.response.use((response) => {

    
    if (response.data.toast) {
        Vue.$toast({
            component: ToastificationContent,
            props: response.data.toast,
        })
    }
    if (response.data.redirect) {
        router.push(response.data.redirect)
    }
    return Promise.resolve(response);
}, (error) => {

    if(error.response){
        if(error.response.status == 422){
            if (error.response.data.message) {
                Vue.$toast({
                    component: ToastificationContent,
                    props: {
                        title : 'Validation formulaire',
                        text: error.response.data.message,
                        variant: 'danger',
                        
                    },
                })
            }
        }

        if(error.response.status == 401){
            localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
            localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

            // Remove userData from localStorage
            localStorage.removeItem('userData')
 

            // Redirect to login page
            router.push({ name: 'auth-login' })
         
        }
    }
    return Promise.reject(error);
});
Vue.prototype.$http = axiosIns

export default axiosIns
