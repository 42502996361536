import axios from '@axios'

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {
 
    },
    actions: {
        fetchStock(ctx, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/api/vehicules/stock', { params: payload })
                    .then(response => resolve(response.data))
                    .catch(error => reject(error))
            })
        },
        addVehicule(ctx,payload){
            return new Promise((resolve, reject) => {
                axios
                    .post('/api/vehicules/stock/add', payload )
                    .then(response => resolve(response.data))
                    .catch(error => reject(error))
            })
        },
        updateCanauxPrixAnnonce(ctx,payload){
            return new Promise((resolve, reject) => {
                axios
                    .post('/api/vehicules/update-canaux-prix-annonce', payload )
                    .then(response => resolve(response.data))
                    .catch(error => reject(error))
            })
        },

        fetchVehicule(ctx, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/api/vehicules/stock/' + payload.vehicule_id  + '/show')
                    .then(response => resolve(response.data))
                    .catch(error => reject(error))
            })
        },
        
    },
}
