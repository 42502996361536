import Vue from 'vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
import VueAutosuggest from "vue-autosuggest";

import {
    BTable,
    BPagination,
    BButton,
    BLink,
    BTabs,
    BTab,
    BCardText,
    BCardHeader,
    BFormGroup,
    BAvatar,
    BBadge,
    BCardBody,
    BCol,
    BRow,
    BCard,
    BNav,
    BNavItem,
    BFormCheckbox,
    BFormDatepicker,
    BFormTextarea,
    BForm,
    BDropdown,
    BDropdownItem,
    BCardTitle,
    BSpinner,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BMedia,
    BMediaAside,
    BMediaBody,
    BListGroup,
    BListGroupItem,
    BInputGroupAppend,
    BFormFile,
    BImg,
    BModal,
    BOverlay,
    BCardFooter,
    BFormSelectOption,
    BFormSelect,
    BDropdownItemButton
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
Vue.component(FeatherIcon.name, FeatherIcon)
Vue.component(BRow.name, BRow)
Vue.component(BCol.name, BCol)
Vue.component('b-nav', BNav)
Vue.component('b-nav-item', BNavItem)
Vue.component('b-card', BCard)
Vue.component('b-button', BButton)
Vue.component('v-select', vSelect)
Vue.component('b-form-checkbox', BFormCheckbox)
Vue.component('b-form-datepicker', BFormDatepicker)
Vue.component('b-form-textarea', BFormTextarea)
Vue.component('validation-provider', ValidationProvider)
Vue.component('b-dropdown', BDropdown)
Vue.component('b-dropdown-item', BDropdownItem)
Vue.component('b-form', BForm)
Vue.component('cleave', Cleave)
Vue.component('b-pagination', BPagination)
Vue.component('b-tabs', BTabs)
Vue.component('b-tab', BTab)
Vue.component('b-card-text', BCardText)
Vue.component('b-card-header', BCardHeader)
Vue.component('b-card-body', BCardBody)
Vue.component('b-card-title', BCardTitle)
Vue.component('b-spinner', BSpinner)
Vue.component('b-form-group', BFormGroup)
Vue.component('b-form-input', BFormInput)
Vue.component('b-input-group', BInputGroup)
Vue.component('b-input-group-prepend', BInputGroupPrepend)
Vue.component('b-input-group-append', BInputGroupAppend)
Vue.component('b-media', BMedia)
Vue.component('b-media-aside', BMediaAside)
Vue.component('b-avatar', BAvatar)
Vue.component('b-media-body', BMediaBody)
Vue.component('b-list-group', BListGroup)
Vue.component('b-list-group-item', BListGroupItem)
Vue.component('b-badge', BBadge)
Vue.component('b-form-file', BFormFile)
Vue.component('b-img', BImg)
Vue.component('b-modal', BModal)
Vue.component('b-link', BLink)
Vue.component('b-overlay', BOverlay)
Vue.component('b-card-footer', BCardFooter)
Vue.component('b-form-select', BFormSelect )
Vue.component('b-form-select-option', BFormSelectOption)
Vue.component('b-dropdown-item-button', BDropdownItemButton)
Vue.use(VueAutosuggest);
 
 