import axios from '@axios'

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {
 
    },
    actions: {
        fetchClients(ctx, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/api/clients', { params: payload })
                    .then(response => resolve(response.data))
                    .catch(error => reject(error))
            })
        },
        fetchClient(ctx, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/api/clients/' + payload.client_id )
                    .then(response => resolve(response.data.data))
                    .catch(error => reject(error))
            })
        },
        storeClient(ctx,payload){
            return new Promise((resolve, reject) => {
                axios
                    .post('/api/clients/store', payload )
                    .then(response => resolve(response.data))
                    .catch(error => reject(error))
            })
        }
        
    },
}
