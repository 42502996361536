import axios from '@axios'

export default {
    namespaced: true,
    state: {
        initialState : {
            sites : [],
            zones : [],
            genres : [],
            vehicule_statuts_ventes : [],
            vehicule_garanties : [],
            vehicule_provenances : [],
            vehicule_origines : [],
            boolean : [],
            peinture : [],
            concessions : [],
            expertise_famille_options : [],
            expertise_operations_options : [],
            
        },
        concession : false,
        site : false,
    },
    getters: {},
    mutations: {
        SET_INITIAL_STATE(state,payload){
            state.initialState = payload
        },
        SET_SITE(state, payload) {
            state.site = payload.site;
            state.concession = payload.concession;
            localStorage.setItem('current_site', JSON.stringify(payload.site));
            localStorage.setItem('current_concession', JSON.stringify(payload.concession));
        }
    },
    actions: {
        fetchInitialConfig(ctx, payload) {
            let site = JSON.parse(localStorage.getItem('current_site'));
            let concession = JSON.parse(localStorage.getItem('current_concession'));
            if(site || concession){
                ctx.commit("SET_SITE", {site : site, concession : concession})
            }
            
            return new Promise((resolve, reject) => {
                axios
                    .get('/api/initial-config', { params: payload })
                    .then(function(response){
                        ctx.commit("SET_INITIAL_STATE",response.data )
                        
                        resolve(response.data)
                    })
                    .catch(error => reject(error))
            })
        },
 
        
    },
}
